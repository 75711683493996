import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import NavDropdown from "react-bootstrap/NavDropdown";
import Dropdown from "react-bootstrap/Dropdown";
import Alert from "react-bootstrap/Alert";
import { postData, getCurrentUser } from "../../api";
import Loader from "../../components/Loader";
import Add from "./Add";
import Delete from "./Delete";
import Play from "./Play";
import "./style.scss";
const Dashboard = () => {
  const [videoList, setVideoList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [addShowModal, setAddShowModal] = useState(false);
  const [singleVideo, setSingleVideo] = useState(null);
  const [deleteShowModal, setDeleteShowModal] = useState(false);
  const [playShowModal, setPlayShowModal] = useState(false);
  const [paginate, setPaginate] = useState({
    limit: 12,
    start: 0,
  });
  useEffect(() => {
    getUsetList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginate.start, paginate.limit]);
  const getUsetList = async () => {
    setLoading(true);
    let obj = {
      creator_id: getCurrentUser()?._id,
      limit: paginate.limit,
      start: paginate.start + 1,
    };
    try {
      const res = await postData("/upload-video-list", {}, obj);
      if (res && res.status === 1) {
        res.data.upload_videos.forEach((element) => {
          element.updated_on = element.updated_at;
        });
        const totalPageCount = calculatePagesCount(
          paginate.limit,
          res.data.total_count
        );
        setTotalCount(totalPageCount);
        setVideoList(res.data.upload_videos);
      } else {
        setVideoList([]);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };
  const playClick = (row) => {
    setSingleVideo(row);
    setPlayShowModal(true);
  };
  const addClick = () => {
    setAddShowModal(true);
  };
  const deleteClick = (video) => {
    setSingleVideo(video);
    setDeleteShowModal(true);
  };
  const onClose = (status) => {
    if (status) getUsetList();
    setAddShowModal(false);
    setPlayShowModal(false);
    setDeleteShowModal(false);
  };
  const handlePageChange = (page) => {
    setPaginate((prev) => ({
      ...prev,
      start: page.selected,
    }));
  };
  const calculatePagesCount = (pageSize, totalCount) => {
    return totalCount < pageSize ? 1 : Math.ceil(totalCount / pageSize);
  };
  return (
    <section className="upload-section">
      <div className="container">
        <div className="row">
          {videoList && videoList.length ? (
            <React.Fragment>
              {!loading &&
                videoList.map((video) => (
                  <div className="col-md-3 mb-4" key={video._id}>
                    <div className="video-box">
                      <div className="vidoe">
                        <video src={video.video_url} poster={video.video_thumbnail}></video>
                        <div className="play-button">
                          <button
                            className="btn btn-primary text-white"
                            onClick={() => playClick(video)}
                          >
                            <i className="fa fa-play"></i>
                          </button>
                        </div>
                      </div>
                      <div className="content">
                        <p className="mb-0 text-color-02 fw-400 f-12">
                          Uploaded on <span>{video.updated_on}</span>
                        </p>
                        <div>
                          <Dropdown align="end">
                            <Dropdown.Toggle variant="" id="dropdown-basic">
                              <i className="fa fa-ellipsis-v text-color-02"></i>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <NavDropdown.Item
                                onClick={() => deleteClick(video)}
                              >
                                Delete
                              </NavDropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              <div className="col-md-12">{loading && <Loader />}</div>
            </React.Fragment>
          ) : (
            <div className="col-md-12">
              <Alert variant="secondary" className="text-center">
                No Videos
              </Alert>
            </div>
          )}
        </div>
      </div>
      <div className="upload-video">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-8">
              <ReactPaginate
                previousLabel="<"
                nextLabel=">"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                pageCount={totalCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageChange}
                containerClassName="pagination"
                activeClassName="active"
                forcePage={paginate.start}
              />
            </div>
            <div className="col-md-4 text-end">
              <button
                className="btn btn-primary btn-lg text-white rounded-pill f-14 fw-600"
                onClick={addClick}
              >
                <i className="fa fa-plus me-2"></i> Upload Video
              </button>
            </div>
          </div>
        </div>
      </div>
      {addShowModal ? <Add show={addShowModal} onClose={onClose} /> : null}
      {deleteShowModal && (
        <Delete
          show={deleteShowModal}
          onClose={onClose}
          singleVideo={singleVideo}
        />
      )}
      {playShowModal && (
        <Play
          show={playShowModal}
          onClose={onClose}
          singleVideo={singleVideo}
        />
      )}
    </section>
  );
};
export default Dashboard;
