import { Form } from "react-bootstrap";
import "./style.scss";
const Input = ({
  label,
  type,
  name,
  value,
  onChange,
  placeholder,
  size,
  errorMessage,
  isInvalid,
  min,
  max,
  maxLength,
  disabled,
  addText,
  onBlur,
  pattern,
  accept,
  autoComplete,
}) => {
  return (
    <Form.Group className={"form-group " + size} controlId={name}>
      <Form.Label id={name} className="fw-500 f-14 text-black">
        {label}
      </Form.Label>
      <Form.Control
        required
        type={type}
        name={name}
        value={value}
        isInvalid={isInvalid}
        placeholder={placeholder}
        onChange={onChange}
        min={min}
        maxLength={maxLength}
        disabled={disabled}
        autoComplete="off"
        onBlur={onBlur}
        accept={accept}
        pattern={pattern}
      />
      <Form.Control.Feedback
        tooltip={true}
        type="invalid"
        style={{ right: "0" }}
      >
        {errorMessage}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default Input;
