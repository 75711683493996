import React from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Dropdown from "react-bootstrap/Dropdown";
//image
import Logo from "../../assets/images/logo.png";
import Sample from "../../assets/images/sample.png";

//style
import "./style.scss";
import { useNavigate } from "react-router-dom";
import { getCurrentUser } from "../../api";

const Header = (props) => {
  const navigate = useNavigate();
  const logOut = () => {
    localStorage.clear();
    navigate("/login");
  };
  return (
    <>
      <div className="header">
        <Container fluid>
          <Navbar>
            <Navbar.Brand>
              <img src={Logo} className="logo" alt="logo" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="navbar-dark-example" />
            <Navbar.Collapse
              id="navbar-dark-example"
              className="custom-collapse"
            >
              <div className="user-logo">
                <img
                  src={
                    getCurrentUser()?.profile?.img
                      ? getCurrentUser()?.profile?.img
                      : Sample
                  }
                  alt="user"
                />
                <Dropdown align="end">
                  <Dropdown.Toggle variant="" id="dropdown-basic">
                    {getCurrentUser()?.name}
                    <span className="mb-0 f-12">
                      {getCurrentUser()?.user_name}
                    </span>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <NavDropdown.Item onClick={logOut}>
                      Log Out
                    </NavDropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </Navbar.Collapse>
          </Navbar>
        </Container>
      </div>
    </>
  );
};

export default Header;
