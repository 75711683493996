import React from "react";
import FormModal from "../../components/Modal";
const Play = ({ show, onClose, singleVideo }) => {
  return (
    <FormModal show={show} onClose={onClose} heading="Video" size="lg">
      {singleVideo && (
        <div className="row">
          <div className="col-md-12">
            <div className="ratio ratio-16x9">
              <iframe
                className="embed-responsive-item"
                src={singleVideo.video_url}
                title={singleVideo._id}
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      )}
    </FormModal>
  );
};

export default Play;
