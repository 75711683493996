import { BrowserRouter, Routes, Route } from "react-router-dom";
//pages
import Login from "./pages/Login";
import PaymentSuccess from "./pages/PaymentSuccess";
import SetPassword from "./pages/Createpassword";
import ResetPassword from "./pages/ResetPassword";
import Dashboard from "./pages/Dashboard";
import Layout from "./Layout";
import "./App.scss";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/login" exact={true} element={<Login />} />
          <Route
            path="/stripe-redirect-success"
            exact={true}
            element={<PaymentSuccess />}
          />

          <Route
            path="/setpassword/:id"
            exact={true}
            element={<SetPassword />}
          />
          <Route
            path="/resetpassword/:id"
            exact={true}
            element={<ResetPassword />}
          />
          <Route path="/" element={<Layout />}>
            <Route path="" exact={true} element={<Dashboard />} />
            <Route path="dashBoard" exact={true} element={<Dashboard />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
