import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { postData } from "../../api";
import Loader from "../../components/Loader";
import LeftTop from "../../assets/images/left-top.png";
import LeftBottom from "../../assets/images/left-bottom.png";
import RightTop from "../../assets/images/right-top.png";
import RightBottom from "../../assets/images/right-bottom.png";
import Group from "../../assets/images/Group.png";
import GooglePlay from "../../assets/images/GooglePlay.png";
import "./style.scss";
const PaymentSuccess = (props) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const gotoHome = () => {
    navigate("/login");
  };
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams) {
      const code = searchParams.get("code");
      const state = searchParams.get("state");
      getPayment(code, state);
    } else {
      gotoHome();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getPayment = async (code, state) => {
    setLoading(true);
    try {
      const getData = await postData(
        "/stripe-redirect-web",
        {},
        {
          code,
          state,
        }
      );
      if (getData && getData.status === 1) {
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };
  return (
    <section className="setpassword-section">
      <img
        className="position-absolute top-0 start-0"
        src={LeftTop}
        alt="crunch"
      />
      <img
        className="position-absolute bottom-0 start-0"
        src={LeftBottom}
        alt="crunch"
      />
      <img
        className="position-absolute end-0 topcircle"
        src={RightTop}
        alt="crunch"
      />
      <img
        className="position-absolute end-0 bottomcircle"
        src={RightBottom}
        alt="crunch"
      />
      <div className="container">
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-1"></div>
              <div className="col-md-10">
                {loading && <Loader />}
                {!loading && (
                  <div className="login-box">
                    <div className="content">
                      <div className="paid-status mb-4">
                        <i className="fa fa-check"></i>
                      </div>
                      <h4 className="pb-1 fw-600 text-center"> Success! </h4>
                      <p className="fw-400 f-16 mb-3 mt-1 text-gray1 text-center">
                        Your stripe account is created successfully now you can
                        manage your supscription model
                      </p>

                      <div className="mt-4 pt-4 Applink text-center">
                        <h6 className="fw-400 f-16 mb-3 text-black text-center">
                          You can login to your creator profile or Download
                          crunch on
                        </h6>
                        <a
                          className="m-1"
                          href="https://play.google.com/store/apps/details?id=com.trainerize.crunchcanada"
                        >
                          <img src={Group} alt="group" />
                        </a>
                        <a className="m-1" href="https://play.google.com/store/apps/details?id=com.trainerize.crunchcanada">
                          <img src={GooglePlay} alt="play" />
                        </a>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="col-md-1"></div>
            </div>
          </div>
          <div className="col-md-6"></div>
        </div>
      </div>
    </section>
  );
};

export default PaymentSuccess;
