//export const baseURL = "https://crunchapi.underdev.in/api";
export const baseURL = "https://uat-crunchapi.underdev.in/api";
// export const baseURL = "https://api.teapoy.app/api";

export const awsConfig = {
  bucketName: "crunch-dev-in",
  dirName: "uploads" /* optional */,
  region: "ap-south-1",
  accessKeyId: "AKIA6PXV6WM422AM3L5Z",
  secretAccessKey: "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",
};

export const isLogedIn = () => {
  return !!localStorage.getItem("crunch_creator_token");
};
export const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("crunch_creator_user"));
};
export const postData = async (url = "", headers = {}, data = {}) => {
  try {
    const response = await fetch(`${baseURL + url}`, {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${getCurrentUser()?.token}`,
      },
      body: JSON.stringify(data),
    });

    const responseData = await response.json();
    if (responseData.statusCode === 401) {
      // alert("Your Session Has Been Expired.Please Login Again");
      localStorage.clear();
      window.location = "/login";
    }
    return responseData;
  } catch (e) {
    return e;
  }
};

export const getData = async (url = "", headers = {}) => {
  try {
    const response = await fetch(`${baseURL + url}`, {
      method: "GET",
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${getCurrentUser()?.token}`,
      },
    });
    const responseData = await response.json();
    if (responseData.statusCode === 401) {
      localStorage.clear();
      window.location = "/login";
    }
    return responseData;
  } catch (e) {
    return e;
  }
};
