import React, { useState } from "react";
import Button from "../../components/Form/Button";
import FormModal from "../../components/Modal";
import { awsConfig, postData } from "../../api";
import { toast } from "react-toastify";
import { deleteFile } from "react-s3";
const Delete = ({ show, onClose, singleVideo }) => {
  const [loading, setLoading] = useState(false);
  const getFileName = () => {
    let name = singleVideo.video_url.split("/");
    return name[name.length - 1];
  };
  const saveClick = async () => {
    setLoading(true);
    const res = await deleteFile(getFileName(), awsConfig);
    if (res) {
      deleteVideo(res);
    } else {
      setLoading(false);
    }
  };
  const deleteVideo = async () => {
    setLoading(true);
    let obj = {
      video_id: singleVideo._id,
    };
    const res = await postData("/delete-video", {}, obj);
    if (res.status === 1) {
      setLoading(false);
      toast.success(res.message, { theme: "colored" });
      onClose(true);
    } else {
      toast.error(res.message, { theme: "colored" });
      setLoading(false);
    }
  };
  const cancelClick = () => {
    onClose(false);
  };

  return (
    <FormModal show={show} onClose={onClose} heading="Delete Video">
      <div className="form-group">
        <p>Are you sure you want to delete this video ?</p>
      </div>
      <div className="text-end">
        <Button
          type="button"
          className="btn-default text-blacksix me-3"
          onClick={cancelClick}
        >
          No
        </Button>
        <Button
          type="button"
          disabled={loading}
          loading={loading}
          onClick={saveClick}
          className="btn-primary text-white"
        >
          Yes
        </Button>
      </div>
    </FormModal>
  );
};

export default Delete;
