import React from "react";
import Header from "./Header";

import { Outlet } from "react-router-dom";
//scss
import "./style.scss";
import PrivateRoute from "../privateRoute";
import "react-toastify/dist/ReactToastify.css";
import Toast from "../components/Toast";
//pages

export default function Layout(props) {
  // const [sidebarHide, setSidebarHide] = useState(false);
  return (
    <>
      <PrivateRoute>
        <Header />
        <main className="main-layout">
          <div className="main-body fixed">
            <Toast />
            <Outlet></Outlet>
          </div>
        </main>
      </PrivateRoute>
    </>
  );
}
