import React, { useState } from "react";
import { Form } from "react-bootstrap";
import "./style.scss";
import logo from "../../assets/images/logo.png";
import Input from "../../components/Form/Input";
import Button from "../../components/Form/Button";
import { useNavigate } from "react-router-dom";
import { postData } from "../../api";
import { toast } from "react-toastify";
import LeftTop from "../../assets/images/left-top.png";
import LeftBottom from "../../assets/images/left-bottom.png";
import RightTop from "../../assets/images/right-top.png";
import RightBottom from "../../assets/images/right-bottom.png";
const Login = () => {
  let navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loginObject, setLoginObject] = useState({
    email: "",
    password: "",
  });

  const handleInput = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setLoginObject((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    if (form.checkValidity() === true) {
      loginUser();
    }
  };
  const loginUser = async () => {
    setLoading(true);
    const res = await postData("/creator-login", {}, loginObject);
    if (res.status === 1) {
      setLoading(false);
      localStorage.setItem("crunch_creator_user", JSON.stringify(res.data));
      localStorage.setItem("crunch_creator_token", res.data.token);
      navigate("/");
    } else {
      toast.error(res.message, { theme: "colored" });
      setLoading(false);
    }
  };

  return (
    <section className="login-section">
      <img
        className="position-absolute top-0 start-0"
        src={LeftTop}
        alt="crunch"
      />
      <img
        className="position-absolute bottom-0 start-0"
        src={LeftBottom}
        alt="crunch"
      />
      <img
        className="position-absolute end-0 topcircle"
        src={RightTop}
        alt="crunch"
      />
      <img
        className="position-absolute end-0 bottomcircle"
        src={RightBottom}
        alt="crunch"
      />
      <div className="container">
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-6">
            <div className="login-box">
              <div className="content">
                <div className="img mb-5 text-center">
                  <img src={logo} alt="logo" />
                </div>
                <h4 className="pb-1 fw-700 text-center">
                  Welcome to crunch creator cloud
                </h4>
                <p className="fw-500 f-14 mb-3 mt-1 text-center">
                  You can upload your videos and use them when do you need to
                  upload a content
                </p>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-12">
                      <Input
                        label=" Email"
                        type="email"
                        name="email"
                        value={loginObject.email}
                        size="lg"
                        placeholder="Enter your email"
                        errorMessage="Email is not valid"
                        onChange={handleInput}
                      ></Input>
                      <Input
                        label="Password"
                        type="password"
                        name="password"
                        size="lg"
                        placeholder="Enter your password"
                        errorMessage="Password is required"
                        value={loginObject.password}
                        onChange={handleInput}
                      ></Input>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-1"></div>
                    <div className="col-md-10 text-center">
                      <Button
                        type="submit"
                        disabled={loading}
                        loading={loading}
                        className="btn-primary text-white"
                      >
                        Login
                      </Button>
                    </div>
                    <div className="col-md-1"></div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
          <div className="col-md-3"></div>
        </div>
      </div>
    </section>
  );
};

export default Login;
