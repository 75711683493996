import React, { useState } from "react";
import { Form } from "react-bootstrap";
import Input from "../../components/Form/Input";
import Button from "../../components/Form/Button";
import FormModal from "../../components/Modal";
import { awsConfig, postData } from "../../api";
import { toast } from "react-toastify";
import { uploadFile } from "react-s3";
window.Buffer = window.Buffer || require("buffer").Buffer;
const Add = ({ show, onClose }) => {
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fileObject, setFileObject] = useState({
    uploadFile: null,
    video_thumbnail:null,
    duration: 0,
  });
  const getDateFormat = () => {
    const date = new Date();
    const month = date.getMonth();
    const day = date.getDay();
    const minute = date.getMinutes();
    const second = date.getSeconds();
    return `video${minute}-${second}-${day}-${month}`;
  };
  const getVideoDuration = (file,e) => {
    var video = document.createElement("video");
    video.preload = "metadata";
    video.onloadedmetadata = function () {
      if(video.duration <= 900){
        window.URL.revokeObjectURL(video.src);
        setFileObject((prev) => ({
          ...prev,
          duration: video.duration,
        }));
      }
      else{
        setFileObject({uploadFile:null});
        e.target.value=null;
        toast.error('video length must be less then 15 minutes', { theme: "colored" });
      }
    };
    video.src = URL.createObjectURL(file);
  };
  const handleInput = (e) => {
    let file = e.target.files[0];
    Object.defineProperty( file, 'name', {
      writable: true,
      value:file.name.replace(/[^A-Z0-9]+/ig, "_")
    });
    getVideoDuration(file,e);
    const fileName = `${getDateFormat()}-${file.name}`;
    const uploadFile = new File([file], fileName, {
      type: file.type,
    });
    const name = e.target.name;
    setFileObject((prev) => ({
      ...prev,
      [name]: file,
    }));
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    if (form.checkValidity() === true) {
      saveClick();
    }
  };
  const saveClick = async () => {
    setLoading(true);
    const video_res = await uploadFile(fileObject.uploadFile, awsConfig);
    const image_res = await uploadFile(fileObject.video_thumbnail, awsConfig);
    if (video_res && image_res) {
      addVideo(video_res,image_res);
    } else {
      setLoading(false);
    }
  };

  const addVideo = async (video_res,image_res) => {
    setLoading(true);
    let obj = {
      video_url: video_res.location,
      video_thumbnail:image_res.location,
      duration: fileObject.duration,
    };
    const res = await postData("/store-upload-video", {}, obj);
    if (res.status === 1) {
      setLoading(false);
      toast.success(res.message, { theme: "colored" });
      onClose(true);
    } else {
      toast.error(res.message, { theme: "colored" });
      setLoading(false);
    }
  };

  const cancelClick = () => {
    onClose(false);
  };
  return (
    <FormModal show={show} onClose={onClose} heading="Upload video">
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Input
          label="Upload file"
          type="file"
          name="uploadFile"
          placeholder="Upload file"
          errorMessage="File is required"
          accept="video/*"
          onChange={handleInput}
        ></Input>
        <Input
          label="Upload thumbnail"
          type="file"
          name="video_thumbnail"
          placeholder="Upload thumbnail"
          errorMessage="File is required"
          accept="image/*"
          onChange={handleInput}
        ></Input>
        <small className="form-text text-muted f-12">Note: Please upload thumbnail image in the size 450 × 905 ~ (Portrait)</small>
        <div className="text-end mt-3">
          <Button
            type="button"
            className="btn-default text-blacksix me-2"
            onClick={cancelClick}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            disabled={loading}
            loading={loading}
            className="btn-primary text-white"
          >
            Add
          </Button>
        </div>
      </Form>
    </FormModal>
  );
};

export default Add;
